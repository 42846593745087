import Big from 'big.js';
import { useGlobalMyInfo } from './useGlobalMyInfo';

export function BlurComponent({
	children,
	className = '',
}: { children: any; className?: string }) {
	const [stateParams] = useGlobalMyInfo();
	if (!stateParams) {
		return children;
	}

	const { globalSensitiveInformationHiding } = stateParams;

	return (
		<div
			className={
				(globalSensitiveInformationHiding ? ' blur-3 ' : '') + className
			}
		>
			{/* {children} */}
			{children instanceof Big ? children.toString() : children}
		</div>
	);
}
